body {
  color: #000;
}

svg[data-icon] {fill: #000; stroke: #000;}

body.dark {
  background-color: #000;
  color: #fff;
}

.dark h1.title > a {color: #fff;}

.dark svg[data-icon] {fill: #fff; stroke: #fff;}

.dark table.torrent-list.table-bordered td, .dark table.torrent-list.table-bordered th {
  border-color: #575757;
}

.table.small th, .table.small td {
  font-size: 14px;
}
.table.table-bordered thead td, .table.table-bordered thead th {
  border-bottom-width: 1px;
}

*:focus {
  box-shadow: none !important;
  outline: none !important;
}

h1.title > a {
  color: #000;
}

h1.title > a:hover {
  text-decoration: none;
}


@media (max-width: 991px) {
  .table.torrent-list tr td:first-child, .table.torrent-list tr th:first-child {
      white-space: unset;
      word-break: break-all;
  }
}

@media (max-width: 480px) {
  .table.torrent-list th, .table.torrent-list td {
    font-size: 13px;
    padding: .2rem;
  }
}

@media (max-width: 575px) {
  /* .navbar-nav {
    position: absolute;
    right: 40px;
  } */
  .search-form {
    width: 100%;
  }
}

.navbar-dark .navbar-toggler {
  border-color: transparent;
}
.navbar-toggler-icon {
  height: 1.2em;
  width: 1.2em;
}